import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Text,
  Flex,
  Sticky,
  StatusPopup,
  Link,
  Token,
  ActionButton,
  VStack,
  DetailsCell,
  Group,
  DetailsCellSkeleton,
} from '@revolut/ui-kit'
import { getCurrentTimezone } from '@src/utils/timezones'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import SchedulingLinkExpired from '@src/pages/CandidateScheduling/SchedulingLinkExpired'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import {
  createCandidateAppointment,
  getAvailableCandidateSlots,
  useCheckSchedulingReEngagementConsentNeeded,
  useGetCandidateInterview,
  useGetPublicTimezones,
} from '@src/api/recruitment/candidateScheduling'
import ConfirmedAppointment from '@src/pages/CandidateScheduling/ConfirmedAppointment'
import { useGetChosenSlot } from '@src/pages/CandidateScheduling/hooks'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import LazyLoadPlaceholder from '@components/LazyLoadPlaceholder/LazyLoadPlaceholder'
import { AvailableCandidateSlots, DurationUnitType } from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import Loader from '@components/CommonSC/Loader'
import ReEngageConsent from '@components/ReEngageConsent/ReEngageConsent'
import { SelectSlotCalendar } from '@src/pages/CandidateScheduling/SelectSlotCalendar'
import { FormattedMessage } from 'react-intl'
import { SelectTimezone } from '@src/pages/CandidateScheduling/SelectTimezone'

type InterviewDetails = {
  chosenEventId?: string
  duration?: number | null
  durationUnit?: DurationUnitType
  id?: number
  recruiterEmail?: string
  timeZoneId?: string
  title?: string
}

type CandidateSelectSlotsProps = {
  confirmAppointment: boolean
  interview?: InterviewDetails
  isLoadingInterview: boolean
  isLoadingTimeZones: boolean
  noPadding?: boolean
  timeZones?: {
    options: OptionInterface[]
  }
  token: string
  onCreateCandidateAppointment: (
    timeZone: string,
    slotId: string,
    slot?: AvailableCandidateSlots,
  ) => Promise<void>
  onGetAvailableCandidateSlots: (
    token: string,
    page: number,
    eventId?: string,
  ) => Promise<{
    slots: AvailableCandidateSlots[]
    count: number
  }>
  onRefetchInterview?: () => void
  onSlotChange?: () => void
  footer?: React.ReactNode
}

export const CandidateSelectSlots = ({
  confirmAppointment,
  interview,
  isLoadingInterview,
  isLoadingTimeZones,
  noPadding = false,
  timeZones,
  token,
  onCreateCandidateAppointment,
  onGetAvailableCandidateSlots,
  onRefetchInterview,
  onSlotChange,
  footer,
}: CandidateSelectSlotsProps) => {
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [slots, setSlots] = useState<AvailableCandidateSlots[]>([])
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)

  const currentTimeZoneId = getCurrentTimezone()

  const [timeZone, setTimeZone] = useState<OptionInterface>()
  const [sendingLoading, setSendingLoading] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState<string | null>()

  const timeZoneId = timeZone ? String(timeZone.id) : currentTimeZoneId

  const normalizedSlots = useGroupByDaySlots(timeZoneId, slots)
  const chosenSlot = useGetChosenSlot(slots, interview?.chosenEventId)

  useEffect(() => {
    if (timeZones?.options && !timeZone) {
      setTimeZone(timeZones.options.find(item => item.id === currentTimeZoneId))
    }
  }, [timeZones])

  const onSubmit = async () => {
    setSendingLoading(true)

    try {
      await onCreateCandidateAppointment(
        timeZoneId,
        selectedSlot!,
        slots.find(s => s.id === selectedSlot),
      )
      if (onRefetchInterview) {
        onRefetchInterview()
      }
    } catch (e) {
      setErrorPopupOpen(true)
    } finally {
      setSendingLoading(false)
    }
  }

  const onLoadMore = async () => {
    if ((totalCount !== null && totalCount === slots.length) || loadingSlots) {
      return
    }

    setLoadingSlots(true)

    try {
      const resp = await onGetAvailableCandidateSlots(token, currentPage)

      setSlots([...slots, ...resp.slots])
      setCurrentPage(currentPage + 1)
      setTotalCount(resp.count)
    } finally {
      setLoadingSlots(false)
    }
  }

  if (isLoadingInterview) {
    return <Loader />
  }

  if (!interview && !isLoadingInterview) {
    return <SchedulingLinkExpired />
  }

  if (confirmAppointment && chosenSlot && interview?.timeZoneId) {
    return (
      <ConfirmedAppointment
        date={chosenSlot.event_start_datetime}
        timeZone={interview.timeZoneId}
      />
    )
  }

  return (
    <>
      <StatusPopup
        variant="error"
        open={errorPopupOpen}
        onClose={() => {
          setErrorPopupOpen(false)
        }}
      >
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        {interview && (
          <StatusPopup.Description>
            Please refresh the page or reach out to the recruiter{' '}
            {interview.recruiterEmail && (
              <>
                (
                <Link href={`mailto:${interview.recruiterEmail}`}>
                  {interview.recruiterEmail}
                </Link>
                )
              </>
            )}
          </StatusPopup.Description>
        )}
      </StatusPopup>
      <VStack
        maxWidth={{ all: 400, md: 600 }}
        gap="s-16"
        px={noPadding ? undefined : 's-16'}
        pt={noPadding ? undefined : 's-32'}
        color={Token.color.foreground}
      >
        <Text variant="heading2">
          <FormattedMessage
            id="recruitment.candidateScheduling.candidateSelectSlots.title"
            defaultMessage="Let's get a suitable date booked for your next interview"
          />
        </Text>
        {interview ? (
          <Group>
            <DetailsCell>
              <DetailsCell.Title>Interview stage</DetailsCell.Title>
              <DetailsCell.Content>{interview.title}</DetailsCell.Content>
            </DetailsCell>
            {interview.duration && interview.durationUnit && (
              <DetailsCell>
                <DetailsCell.Title>Duration</DetailsCell.Title>
                <DetailsCell.Content>
                  {getDuration(interview.duration, interview.durationUnit)}
                </DetailsCell.Content>
              </DetailsCell>
            )}
          </Group>
        ) : (
          <DetailsCellSkeleton />
        )}
        <SelectTimezone
          loading={isLoadingTimeZones}
          options={timeZones?.options || []}
          timezone={timeZone}
          onChange={newTimezone => {
            if (newTimezone) {
              setTimeZone(newTimezone)
            }
          }}
        />
        <SelectSlotCalendar
          loading={loadingSlots}
          slots={normalizedSlots}
          timezone={timeZoneId}
          value={selectedSlot}
          onChange={newSlot => {
            setSelectedSlot(newSlot)
            if (onSlotChange) {
              onSlotChange()
            }
          }}
        />
        <LazyLoadPlaceholder onReach={onLoadMore} />
        {interview &&
          !loadingSlots &&
          totalCount !== null &&
          slots.length === totalCount && (
            <ActionWidget
              title="Not happy with the slots?"
              text="Kindly reach out to the recruiter to request new slots"
            >
              {interview.recruiterEmail ? (
                <ActionButton use="a" href={`mailto:${interview.recruiterEmail}`}>
                  Email recruiter
                </ActionButton>
              ) : null}
            </ActionWidget>
          )}

        <Sticky bottom={0}>
          <Box bg={Token.color.layoutBackground} pt="s-8">
            {footer}
            <Flex justifyContent="center">
              <Button
                elevated
                maxWidth={288}
                disabled={!selectedSlot || sendingLoading}
                pending={sendingLoading}
                onClick={onSubmit}
                data-testid="Confirm availability"
                mb="s-16"
              >
                Confirm availability
              </Button>
            </Flex>
          </Box>
        </Sticky>
      </VStack>
    </>
  )
}

export default () => {
  const { query } = useQuery()
  const { data: timeZones, isLoading: isLoadingTimeZones } = useGetPublicTimezones(
    query.token,
  )
  const {
    data: interview,
    isLoading: isLoadingInterview,
    refetch: refetchInterview,
  } = useGetCandidateInterview(query.token)
  const { data: consentNeededData, isLoading: isConsentLoading } =
    useCheckSchedulingReEngagementConsentNeeded(query.token)
  const [consentCheck, setConsentCheck] = useState<boolean | undefined>(false)

  useEffect(() => {
    if (!consentNeededData?.is_consent_required) {
      setConsentCheck(undefined)
    }
  }, [consentNeededData?.is_consent_required])

  return (
    <CandidateSelectSlots
      confirmAppointment
      interview={
        interview
          ? {
              chosenEventId: interview.chosen_event_id,
              duration: interview.duration || interview.interview_stage.duration,
              durationUnit:
                interview.duration_unit || interview.interview_stage.duration_unit,
              id: interview.id,
              recruiterEmail: interview.coordinator?.email,
              timeZoneId: interview.candidate_timezone?.id,
              title: interview.is_adhoc
                ? 'Catch-up call'
                : interview.interview_stage.title,
            }
          : undefined
      }
      isLoadingInterview={isLoadingInterview}
      isLoadingTimeZones={isLoadingTimeZones}
      timeZones={timeZones}
      token={query.token}
      onCreateCandidateAppointment={async (timeZoneId: string, selectedSlot: string) => {
        await createCandidateAppointment(
          interview!.id!,
          timeZoneId,
          selectedSlot,
          query.token,
          consentCheck,
        )
      }}
      onGetAvailableCandidateSlots={async (token, page) => {
        const res = await getAvailableCandidateSlots(token, page)
        return {
          slots: res.data.results,
          count: res.data.count,
        }
      }}
      onRefetchInterview={() => refetchInterview()}
      footer={
        <ReEngageConsent
          loading={isConsentLoading}
          consentNeeded={consentNeededData?.is_consent_required}
          checked={consentCheck}
          onCheck={setConsentCheck}
          mb="s-16"
        />
      }
    />
  )
}
